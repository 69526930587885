/* FileName: song.scss */

.song-container { 
	min-height: 50vh; 
	display: flex; 
	flex-direction: column; 
	align-items: center; 
	justify-content: center; 
	img { 
		width: 50%; 
		// 
	} 
	h2 { 
		padding: 3rem 1rem 1rem 1rem; 
	} 
	h3 { 
		font-size: 1rem; 
	} 
} 
@media screen and (max-width: 768px) { 
	.song-container { 
		img { 
			width: 60%; 
		} 
	} 
} 
@keyframes rotate { 
	from { 
		transform: rotate(0deg); 
	} 
	to { 
		transform: rotate(360deg); 
	} 
} 

.rotateSong { 
	animation: rotate 20s linear forwards infinite; 
} 
