/*FileName: nav.scss */

h1, 
h4 { 
	color: rgb(9, 70, 9); 
} 

nav { 
	min-height: 15vh; 
	display: flex; 
	justify-content: center; 
	align-items: center; 
	margin: 20px; 
	button { 
		background: transparent; 
		border: none; 
		cursor: pointer; 
		font-size: 16px; 
		margin-left: 20%; 
		border: 2px solid rgb(25, 140, 216); 
		padding: 0.8rem; 
		transition: all 0.3s ease; 
		&:hover { 
			background: rgb(25, 140, 216); 
			color: white; 
		} 
	} 
} 
@media screen and (max-width: 768px) { 
	nav { 
		button { 
			z-index: 10; 
		} 
	} 
} 
