/* { 
	margin: 0; 
	padding: 0; 
	box-sizing: border-box; 
	font-family: "Gilroy", sans-serif; 
} 
body { 
	background: rgb(231, 235, 214); 
	background: linear-gradient( 
		180deg, 
		rgba(231, 235, 214, 1) 0%, 
		rgba(55, 102, 44, 1) 100%
	); 
} */

@import "./library"; 
@import "./player"; 
@import "./song"; 
@import "./nav"; 
h2, 
h3 { 
	color: #6f42c1; 
} 
h3, 
h4 { 
	font-weight: 400; 
} 
button { 
	font-weight: 500; 
} 
